import { combineReducers } from '@reduxjs/toolkit'
import users from './users'
import address from './address'
import notification from './notification'

export const rootReducer = combineReducers({
  users,
  address,
  notification
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
