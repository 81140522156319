import React from 'react-redux'
import { useApiCallback } from '../../hooks/useApiCallback'
import { useMessage } from '../../hooks/useMessage'
import { AddressForm } from '../molecules/Address'
import { addressApi } from '../../api/address.api'
import { ROUTE_PATH } from '../../config/routePaths'
import { useNavigateOnFinished } from '../../hooks/useNavigateOnFinished'

export const AddressCreateContainer = () => {
  const { call, isFinished, inProgress, error } = useApiCallback(async values => {
    const modifiedValues = {
      ...values
    }
    if (!values.phone || values.phone.trim() === '') {
      delete modifiedValues.phone
    }
    return await addressApi.create(modifiedValues)
  })
  useNavigateOnFinished(isFinished && !error, ROUTE_PATH.ADDRESS.LIST)

  const contextHolder = useMessage('success', 'успешно', isFinished)
  return <>{contextHolder}<AddressForm onFinish={call} callInProgress={inProgress}/></>
}
