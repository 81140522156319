import { selectAuthorizedUser } from '../../store/selectors/users'
import { useSelector } from 'react-redux'
import { authReceivedProfile } from '../../store/actions/auth/auth.actions'
import { useApiOnMountDispatch } from '../../hooks/useApiOnMountDispatch'
import { authApi } from '../../api/auth.api'
import { Authorized } from '../pages/Authorized'
import { Unauthorized } from '../pages/Unauthorized'

export const AuthorizedContainer = () => {
  useApiOnMountDispatch(authApi.getProfile, authReceivedProfile)

  const user = useSelector(selectAuthorizedUser)
  return user ? <Authorized /> : <Unauthorized />
}
