import logo from '../../theme/images/logo.png'
import './authorized.css'
import { PrivateRoutes } from './PrivateRoutes'

export const Authorized = () => {
  return (
        <div className={'wrapper_for_authorized flex-column'} >
            <img src={logo} className='logo_small' alt="logo"/>
            <main>
              <PrivateRoutes />
            </main>
        </div>
  )
}
