import { Outlet, Route, Routes } from 'react-router-dom'
import { ChangePassContainer } from '../containers/ChangePass'
import { UserEditProfileContainer } from '../containers/UserEditProfile '
import { AddressCreateContainer } from '../containers/AddressCreate'
import { AddressListContainer } from '../containers/AddressList'
import { AddressEditContainer } from '../containers/AddressEdit'
import { RegisterSuccess } from '../molecules/RegisterSuccess'
import { LogoutContainer } from '../containers/Logout'
import { ROUTE_PATH } from '../../config/routePaths'

export const PrivateRoutes = () => <>
  <Routes>
    <Route index element={<UserEditProfileContainer />}/>
    <Route path={ROUTE_PATH.USER.PROFILE} element={<UserEditProfileContainer />}/>
    <Route path={ROUTE_PATH.USER.CHANGE_PASS} element={<ChangePassContainer />}/>
    <Route path={ROUTE_PATH.USER.EDIT} element={<UserEditProfileContainer />}/>
    <Route path={ROUTE_PATH.ADDRESS.CREATE} element={<AddressCreateContainer />}/>
    <Route path={ROUTE_PATH.ADDRESS.LIST} element={<AddressListContainer />}/>
    <Route path={ROUTE_PATH.ADDRESS.EDIT} element={<AddressEditContainer />}/>
    <Route path={ROUTE_PATH.USER.REGISTER_SUCCESS} element={<RegisterSuccess />}/>
    <Route path={ROUTE_PATH.USER.LOGOUT} element={<LogoutContainer />}/>
  </Routes>
  <Outlet />
</>
