import { NotificationInstance } from 'antd/es/notification/interface'
import { useEffect } from 'react'
import { notificationActions } from '../../store/actions/notification/notification.actions'
import { useDispatch } from 'react-redux'
import { CustomNotificationArgsProps } from '../../types'

interface Props {
  notification: CustomNotificationArgsProps
  api: NotificationInstance
}
export const Notification = ({ notification, api }: Props) => {
  const dispatch = useDispatch()
  useEffect(() => {
    api.open({
      ...notification,
      onClose: () => dispatch(notificationActions.remove(notification.key))
    })
  }, [notification, api, dispatch])
  return null
}
