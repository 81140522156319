import { createReducer } from '@reduxjs/toolkit'
import { notificationActions } from '../../actions/notification/notification.actions'
import { CustomNotificationArgsProps } from '../../../types'

const initialState: CustomNotificationArgsProps[] = []
export default createReducer(initialState, builder => builder
  .addCase(notificationActions.add, (state, action) => ({
    ...state,
    ...action.payload
  })
  ).addCase(notificationActions.remove, (state, action) => {
    return state.filter(el => el.key !== action.payload)
  })
)
