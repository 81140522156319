import React from 'react'
import { ConfigProvider } from 'antd'
import 'antd/dist/reset.css'
import './App.css'
import theme from './theme/theme'
import { NotificationContainer } from './components/containers/Notification'
import { router } from './components/pages/routes'
import { RouterProvider } from 'react-router-dom'

function App (): React.ReactElement {
  return (
    <ConfigProvider theme={theme}>
      <RouterProvider router={router} />
      <NotificationContainer/>
    </ConfigProvider>
  )
}

export default App
