import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom'
import { ROUTE_PATH } from '../../config/routePaths'
import { HealthCheckContainer } from '../containers/HealthCheckContainer'
import { AuthorizedContainer } from '../containers/Authorized'

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path='/*' element={<AuthorizedContainer />}>
      <Route index element={null} />
      {/* common routes */}
      <Route path={ROUTE_PATH.HEALTH_CHECK} element={<HealthCheckContainer />}/>
    </Route>
  )
)
