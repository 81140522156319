import { useSelector } from 'react-redux'
import { selectNotification } from '../../store/selectors/notification'
import { Notification } from '../molecules/Notification'
import { notification } from 'antd'

export const NotificationContainer = () => {
  const notifications = useSelector(selectNotification)
  const [api, contextHolder] = notification.useNotification()
  return <>
    {contextHolder}
    {notifications.map((notification) => (
      <Notification key={notification.key} notification={notification} api={api}/>
    ))}
  </>
}
