import { UserRegister } from '../molecules/UserRegister'
import { useApiCallback } from '../../hooks/useApiCallback'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTE_PATH } from '../../config/routePaths'
import { usersApi } from '../../api/users.api'

export const UserRegisterContainer = () => {
  const { call, isFinished, inProgress } = useApiCallback(usersApi.register)
  const navigate = useNavigate()
  useEffect(() => {
    if (isFinished) {
      navigate(ROUTE_PATH.USER.REGISTER_SUCCESS)
    }
  }, [isFinished, navigate])
  return <UserRegister onFinish={call} callInProgress={inProgress} />
}
