import { CSSProperties } from 'react'
import logo from '../../theme/images/logo.png'
import { Layout } from 'antd'
import { PublicRoutes } from './PublicRoutes'

const { Header, Content } = Layout

const style: Record<string, CSSProperties> = {
  layout: {
    width: '70%',
    margin: '20px auto',
    maxWidth: '1024px',
    textAlign: 'center'
  },
  header: {
    minHeight: '162px',
    marginTop: '20px'
  },
  content: {
    margin: '20px 0'
  }
}

export const Unauthorized = () => {
  return <Layout style={style.layout}>
            <Header style={style.header}>
              <img src={logo} className="logo" alt="logo" />
            </Header>
            <Content style={style.content}>
              <PublicRoutes />
            </Content>
        </Layout>
}
