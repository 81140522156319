import { useDispatch } from 'react-redux'
import { TApiCall } from '../types'
import { useApiDataOnMount } from './useApiDataOnMount'
import { createAction } from '@reduxjs/toolkit'
import { useEffect } from 'react'

export const useApiOnMountDispatch = <T>(apiCall: TApiCall<T>, actionCreator: ReturnType<typeof createAction>) => {
  const dispatch = useDispatch()
  const { data, isFinished } = useApiDataOnMount(apiCall)
  useEffect(() => {
    if (isFinished && data) {
      dispatch(actionCreator(data))
    }
  }, [data, isFinished, actionCreator, dispatch])
}
