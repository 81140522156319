import React from 'react'
import { useNavigate } from 'react-router-dom'
import { setAuthorizationHeader } from '../../config/api'
import { logoutAction } from '../../store/actions/auth/logout.actions'
import { useDispatch } from 'react-redux'

export const LogoutContainer = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  React.useEffect(() => {
    setAuthorizationHeader('')
    dispatch(logoutAction())
    navigate('/') // Редирект на главную страницу
  }, [dispatch, navigate])
  return null
}
