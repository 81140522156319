import { useCallback, useState } from 'react'
import { TApiCall } from '../types'

export const useApiCallback = <T>(apiCall: TApiCall<T>) => {
  const [data, setData] = useState<T>()
  const [inProgress, setInProgress] = useState(false)
  const [isFinished, setIsFinished] = useState(false)
  const [error, setError] = useState<Error | null>(null)
  const call = useCallback(async (...args: Parameters<typeof apiCall>) => {
    setInProgress(true)
    setError(null)
    const response = await apiCall(...args)
      .catch((error) => {
        setError(error as Error)
        console.error(error)
      })
      .finally(() => {
        setInProgress(false)
        setIsFinished(true)
      })
    setData(response?.data)
  }, [apiCall])
  return { data, inProgress, isFinished, call, error }
}
