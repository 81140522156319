
export const ROUTE_PATH = {
  USER: {
    PROFILE: '/profile',
    EDIT: '/profile/edit',
    CHANGE_PASS: '/change-pass',
    FORGOT_PASS: '/forgot-pass',
    FORGOT_PASS_TOKEN: '/forgot-pass/:token',
    REGISTER: '/register',
    LOGIN: '/login',
    LOGOUT: '/logout',
    REGISTER_SUCCESS: '/register-success'
  },
  HEALTH_CHECK: 'healthcheck',
  ADDRESS: {
    CREATE: '/address/create',
    LIST: '/address',
    EDIT: '/address/edit/:id'
  }
}
